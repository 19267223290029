var sw = window.innerWidth;
var sh = window.innerHeight;
var header = document.querySelector("header");
var sticky = 0;
var animationFinished = 0;

// circle function start form here
let anim = [...document.querySelectorAll(".holderCircle")];

let circle = {
  rootMargin: "0px",
  threshold: 0.2,
};

let animate = (entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      $(".holderCircle").addClass("circle_add");
    }
  });
};

let observer = new IntersectionObserver(animate, circle);

anim.forEach((item, idx) => {
  observer.observe(item);
});

function circleStyledAction(i) {
  $(".itemDot").removeClass("active");
  $('[data-tab="' + i + '"]').addClass("active");
  $(".CirItem").removeClass("active");
  $(".CirItem" + i).addClass("active");
  $(".content-tab").removeClass("active");
  $(".content-tab" + i).addClass("active");
  $(".itemDot").css({
    transform: "rotate(" + 0.1 + "deg)",
    transition: "1s",
  });
}

function circleSlider() {
  let i = 1;
  var radius = 100;
  var fields = $(".itemDot");
  var container = $(".dotCircle");
  var width = container.width();
  radius = width / 2.5;

  var height = container.height();
  var angle = 0,
    step = (2 * Math.PI) / fields.length;
  fields.each(function asda() {
    var x = Math.round(
      width / 2 + radius * Math.cos(angle) - $(this).width() / 2
    );
    var y = Math.round(
      height / 2 + radius * Math.sin(angle) - $(this).height() / 2
    );
    if (window.console) {
      console.log($(this).text(), x, y);
    }

    $(this).css({
      left: x + "px",
      top: y + "px",
      left: x + "px",
      top: y + "px",
    });
    angle += step;
  });

  $(".itemDot").mouseenter(function () {
    var dataTab = $(this).data("tab");
    circleStyledAction(dataTab);
  });
  if (animationFinished == 0) {
    var timer = setInterval(function () {
      if ($(".holderCircle").hasClass("circle_add")) {
        // var dataTab = $(".itemDot.active").data("tab");
        if (i < 12) {
          $(".circle_svg path").css({
            "stroke-dashoffset": `${(310 / 11) * (12 - i)}`,
          });
          setTimeout(function () {
            $('[data-tab="' + i + '"]').removeClass("opac");
            circleStyledAction(i);
            i++;
          }, 500);
        } else {
          dataTab = 1;
          i = 1;
          circleStyledAction(i);
          // $(".circle_svg path").css({
          //   "stroke-dashoffset": `0`,
          // });
          animationFinished = 1;
          clearInterval(timer);
        }
      }
    }, 2000);
  }
}

// circle slides complete

function processSlide() {
  let i = 2;
  var radius = 200;
  var fields = $(".circle-process-wrapper .swiper-pagination-bullet");
  var container = $(".circle-process-wrapper .swiper-pagination");
  var width = container.width();
  radius = width / 1.9;

  var height = container.height();
  var angle = 0,
    step = (2 * Math.PI) / fields.length;
  fields.each(function asda() {
    var x = Math.round(
      width / 2 + radius * Math.cos(angle) - $(this).width() / 2
    );
    var y = Math.round(
      height / 2 + radius * Math.sin(angle) - $(this).height() / 2
    );
    if (window.console) {
      console.log($(this).text(), x, y);
    }

    $(this).css({
      left: x + "px",
      top: y + "px",
      // left: x / 16 + 'em',
      // top: y / 16 + 'em',
    });
    angle += step;
  });
}

// Smooth Scroll

if (sw > 577) {
  if (!document.querySelector(".no-butter")) {
    butter.init({
      cancelOnTouch: true,
    });
  }
}

// Font Resizer-------------------Start
function fontResizer() {
  var perc = parseInt(sw) / 118.9375;
  document.body.style.fontSize = perc + "px";
}

// Loader-------------------Start
function pageLoader() {
  setTimeout(function () {
    $(".loader-first").fadeOut();
    $("html").removeClass("loadjs");
  }, 700);
}

// Navigation Active-------------------Start
let toggleMenu = document.querySelector("#toggle");
let htmlEl = document.querySelector("html");

$(".hamburger").click(function () {
  $(this).toggleClass("active");
  $(".navigation").toggleClass("open");
  // $("html").toggleClass("noscroll");
  // $("body").toggleClass("noscroll");
});
$(".menu__menuitem--menulink").click(function () {
  $(".hamburger").removeClass("active");
  $(".navigation").removeClass("open");
});
$(".round-anchor").click(function () {
  $(".hamburger").removeClass("active");
  $(".navigation").removeClass("open");
});

/*
$(".has-child").click(function () {
  $(this).toggleClass("open");
});

$(".has-sub-child").click(function () {
  $(this).toggleClass("open");
  $(this).parent(".sub-menu").parent(".sub-menu-wrapper").toggleClass("open");
});
*/
$(".has-child").click(function (event) {
  event.stopPropagation(); // Stop event propagation
  $(this).toggleClass("open");
  $(".has-sub-child").removeClass("open"); // Remove "open" class from all elements with class ".has-sub-child"
  $(".sub-menu-wrapper").removeClass("open"); // Remove "open" class from all elements with class ".sub-menu-wrapper"
});

$(".has-sub-child").click(function (event) {
  event.stopPropagation(); // Stop event propagation
  $(this).toggleClass("open");
  $(this).parent(".sub-menu").parent(".sub-menu-wrapper").toggleClass("open");
});

// Landscape Mode off----------------Start
function landscapeModeOff() {
  if (sh < 450 && sw > 480 && sw < 820) {
    document.getElementById("portrait-warnning").style.display = "flex";
  } else {
    document.getElementById("portrait-warnning").style.display = "none";
  }
}

// Sticky Header----------------Start
function stickyHeader() {
  if (window.pageYOffset > sticky) {
    header.classList.add("sticky");
  } else {
    header.classList.remove("sticky");
  }
}

// SVG Covert Code-----------Start
let svgs = document.querySelectorAll("img.svg-convert");

svgs.forEach((el) => {
  const imgID = el.getAttribute("id");
  const imgClass = el.getAttribute("class");
  const imgURL = el.getAttribute("src");

  fetch(imgURL)
    .then((response) => response.text())
    .then((data) => {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(data, "text/html");
      let svg = xmlDoc.querySelector("svg");
      if (typeof imgID !== "undefined") {
        svg.setAttribute("id", imgID);
      }

      if (typeof imgClass !== "undefined") {
        svg.setAttribute("class", imgClass + " svg-converted");
      }

      svg.removeAttribute("xmlns:a");

      el.parentNode.replaceChild(svg, el);
    });
});

// Window on Load-----------------Start
window.onload = function () {
  pageLoader();
  if (sw > 1025) {
    fontResizer();
  }

  setTimeout(function () {
    circleSlider();
    processSlide();
  }, 1500);
};

//  page active link
function setNavigation() {
  let current_location = location.pathname.split("/")[1];
  // console.log('hello', current_location);

  let menu_items = document
    .querySelector(".navigation")
    .getElementsByTagName("a");
  if (!current_location) {
    menu_items[0].classList.add("active");
  } else {
    for (let i = 0, len = menu_items.length; i < len; i++) {
      // console.log('============',menu_items[i].getAttribute("href"),current_location);

      // console.log( menu_items[i].getAttribute("href"));
      // console.log(typeof location.pathname)

      // console.log(menu_items[i].getAttribute("href") === location.pathname);

      if (menu_items[i].getAttribute("href") === location.pathname) {
        menu_items[i].classList.add("active");
      }

      if (document.documentElement.getAttribute("lang") === "en") {
        if (
          menu_items[i].getAttribute("href").replace(/^\/|\/$/g, "") ===
          current_location
        ) {
          menu_items[i].classList.add("active");
        }
      }
    }
  }
}
setNavigation();

// page active link - AR
// function setNavigationAR() {
//   // Check if the HTML tag has the "lang" attribute set to "ar"
//   if (document.documentElement.getAttribute("lang") === "ar") {
//     let current_location = location.pathname.split("/ar/")[1];
//     console.log('hello', current_location)
//     let menu_items = document.querySelector(".navigation").getElementsByTagName("a");
//     if (!current_location) {
//       menu_items[0].classList.add("active");
//     } else {
//       for (let i = 0, len = menu_items.length; i < len; i++) {
//         console.log('============',menu_items[i].getAttribute("href"),current_location)
//         if (menu_items[i].getAttribute("href").replace(/^\/|\/$/g, '') === current_location) {
//             menu_items[i].classList.add("active");
//         }
//       }
//     }
//   }
// }
// setNavigation();

// Window On Resize-----------------Start
window.addEventListener("resize", (event) => {
  sw = window.innerWidth;
  sh = window.innerHeight;
  landscapeModeOff();
  if (sw > 1025) {
    if (sw < 1400 && sw > 1300 && sh > 900) {
    } else {
      console.log("------------- iam here");
      fontResizer();
    }
  }
  animationFinished = 1;
  circleSlider();
  processSlide();
});

// Window On Resize-----------------End

// Window On Scroll-----------------Start
window.addEventListener("scroll", (event) => {
  stickyHeader();
});
// Window On Scroll-----------------End

// HomePage Sliders-----Start
if (sw < 577) {
  if (sh > 500) {
    if (document.querySelector(".home")) {
      var homeParallax = new Swiper(".homeParallax", {
        direction: "vertical",
        slidesPerView: 1,
        spaceBetween: 0,
        mousewheel: true,
        speed: 800,
      });
    }
  }
}

// Home Page Hero
// Function to convert numbers to Arabic numerals
function convertToArabic(number) {
  const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
  return String(number)
    .split("")
    .map((digit) => arabicNumerals[digit])
    .join("");
}

// Function to detect language based on HTML lang attribute
function detectLanguage() {
  const htmlLang = document.querySelector("html").getAttribute("lang");
  return htmlLang === "ar" ? "arabic" : "english";
}

if (document.querySelector(".home-hero")) {
  let headerHeight = $(".header").height();

  $(".home-hero__centersec").css("padding-top", headerHeight);
  var leftsecSlider = new Swiper(".home-hero__leftsec--slider", {
    slidesPerView: 0.85,
    loop: true,
    // speed: 1500,
    allowTouchMove: false,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
  });

  let heroSlidertotalSlide = document.querySelectorAll(
    ".home-hero__rightsec--slider .swiper-slide"
  ).length;
  var rightsecSlider = new Swiper(".home-hero__rightsec--slider", {
    watchSlidesProgress: true,
    allowTouchMove: false,
    speed: 1000,
    observer: true,
    autoHeight: true,
    observeParents: true,
    autoplay: {
      enable: true,
      disableOnInteraction: false,
      delay: 3000,
    },
    parallax: true,
    loop: true,
    pagination: {
      el: ".home-hero__rightsec .swiper-pagination",
    },
    on: {
      init: function () {
        var currentSlide = document.querySelector(
          ".home-hero__rightsec .current-slide"
        );
        var allSlides = document.querySelector(
          ".home-hero__rightsec .total-slides"
        );
        var current = this.realIndex + 1;
        if (current > heroSlidertotalSlide) current = 1;
        var idx = current < 10 ? "0" + current : current;
        var tdx =
          heroSlidertotalSlide < 10
            ? "0" + heroSlidertotalSlide
            : heroSlidertotalSlide;

        // Detect the language
        const selectedLanguage = detectLanguage();

        // Convert current slide index and total slides to Arabic numerals if the language is Arabic
        if (selectedLanguage === "arabic") {
          idx = convertToArabic(idx);
          tdx = convertToArabic(tdx);
        }

        currentSlide.innerHTML = idx + ".";
        allSlides.innerHTML = tdx + ".";

        // Parallex--Start
        let swiper1 = this;
        for (let i = 0; i < swiper1.slides.length; i++) {
          $(swiper1.slides[i])
            .find(".img-wrapper")
            .attr({
              "data-swiper-parallax": 0.45 * swiper1.width,
              "data-swiper-paralalx-opacity": 0.5,
            });
        }
        // Parallex--End
      },
      resize: function () {
        this.update();
      },
    },
  });
  rightsecSlider.on("slideChange", function () {
    var currentSlide = document.querySelector(
      ".home-hero__rightsec .current-slide"
    );
    var allSlides = document.querySelector(
      ".home-hero__rightsec .total-slides"
    );
    var current = this.realIndex + 1;
    if (current > heroSlidertotalSlide) current = 1;
    var idx = current < 10 ? "0" + current : current;
    var tdx =
      heroSlidertotalSlide < 10
        ? "0" + heroSlidertotalSlide
        : heroSlidertotalSlide;

    // Detect the language
    const selectedLanguage = detectLanguage();

    // Convert current slide index and total slides to Arabic numerals if the language is Arabic
    if (selectedLanguage === "arabic") {
      idx = convertToArabic(idx);
      tdx = convertToArabic(tdx);
    }

    currentSlide.innerHTML = idx + ".";
    allSlides.innerHTML = tdx + ".";
  });

  var centersecSlider = new Swiper(".home-hero__centersec--slider", {
    slidesPerView: 1,
    loop: true,
    // speed: 1500,
    allowTouchMove: false,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
  });

  if (sw > 576) {
    rightsecSlider.controller.control = centersecSlider;
    centersecSlider.controller.control = leftsecSlider;
  }
}

// Home Our Team Slider
if (document.querySelector(".ourTeam__Slider")) {
  var centersecSlider = new Swiper(".ourTeam__Slider", {
    slidesPerView: 2,
    loop: true,
    speed: 700,
    spaceBetween: 30,
    allowTouchMove: true,
    grabCursor: true,
    navigation: {
      nextEl: ".ourTeam .swiper-button-next",
      prevEl: ".ourTeam .swiper-button-prev",
    },
    breakpoints: {
      // when window width is >= 320px
      768: {
        slidesPerView: 4,
        spaceBetween: 10,
      },
      1400: {
        slidesPerView: "auto",
        spaceBetween: 0,
      },
    },
  });
}

//  Home page - What we do
// Function to update pagination numbers based on language
function updatePaginationNumbers(current, total) {
  const selectedLanguage = detectLanguage();

  if (selectedLanguage === "arabic") {
    current = convertToArabic(current) + ".";
    total = convertToArabic(total) + ".";
  }

  var currentSlide = document.querySelector(
    ".whatWeDo__rightsec .current-slide"
  );
  var allSlides = document.querySelector(".whatWeDo__rightsec .total-slides");
  currentSlide.innerHTML = current;
  allSlides.innerHTML = total;
}

if (document.querySelector(".whatWeDo__gallery")) {
  let whatWeDoTotalSlides = document.querySelectorAll(
    ".whatWeDo__gallery .swiper-slide"
  ).length;

  var whatWeDoGallery = new Swiper(".whatWeDo__gallery", {
    slidesPerView: 1,
    loop: false,
    speed: 1500,
    allowTouchMove: true,
    parallax: true,
    loopedSlides: 20,
    breakpoints: {
      767: {
        loop: false,
      },
    },
    pagination: {
      el: ".whatWeDo__rightsec .swiper-pagination",
      type: "progressbar",
    },
    navigation: {
      nextEl: ".whatWeDo__navigation .swiper-button-next",
      prevEl: ".whatWeDo__navigation .swiper-button-prev",
    },
    on: {
      init: function () {
        var currentSlide = document.querySelector(
          ".whatWeDo__rightsec .current-slide"
        );
        var allSlides = document.querySelector(
          ".whatWeDo__rightsec .total-slides"
        );
        var current = this.realIndex + 1;
        if (current > whatWeDoTotalSlides) current = 1;
        var idx = current < 10 ? "0" + current + "." : current + ".";
        var tdx =
          whatWeDoTotalSlides < 10
            ? "0" + whatWeDoTotalSlides + "."
            : whatWeDoTotalSlides + ".";
        updatePaginationNumbers(idx, tdx);
      },
    },
  });

  whatWeDoGallery.on("slideChange", function () {
    var currentSlide = document.querySelector(
      ".whatWeDo__rightsec .current-slide"
    );
    var allSlides = document.querySelector(".whatWeDo__rightsec .total-slides");
    var current = this.realIndex + 1;
    if (current > whatWeDoTotalSlides) current = 1;
    var idx = current < 10 ? "0" + current + "." : current + ".";
    var tdx =
      whatWeDoTotalSlides < 10
        ? "0" + whatWeDoTotalSlides + "."
        : whatWeDoTotalSlides + ".";
    updatePaginationNumbers(idx, tdx);
  });

  var whatWeDo__headSlider = new Swiper(".whatWeDo__headSlider", {
    slidesPerView: "auto",
    speed: 1500,
    loop: false,
    allowTouchMove: false,
    direction: "vertical",
    effect: "coverflow",
    coverflowEffect: {
      rotate: 0,
      stretch: 80,
      depth: 1,
      modifier: 1,
      slideShadows: false,
    },
    breakpoints: {
      1024: {
        stretch: 70,
      },
    },
  });

  var whatWeDo__descripSlider = new Swiper(".whatWeDo__descripSlider", {
    slidesPerView: 1,
    loop: false,
    speed: 1500,
    allowTouchMove: false,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
  });
  whatWeDoGallery.controller.control = whatWeDo__descripSlider;
  whatWeDo__descripSlider.controller.control = whatWeDo__headSlider;

  if (sw > 1199) {
    if (sw < 1400 && sw > 1300 && sh > 900) {
    } else {
      $(".whatWeDo").mousemove(function (e) {
        $(".slider-cursor").css({
          left: e.clientX,
          top: e.clientY,
        });
      });

      $(".whatWeDo").mouseleave(function (e) {
        $(".slider-cursor").css("transform", "scale(0) translate(-50%, -50%)");
        $(".slider-cursor").css("transition", "transform .5s");
      });

      $(".whatWeDo").mouseenter(function (e) {
        $(".slider-cursor").css("transform", "scale(1) translate(-50%, -50%)");
        $(".slider-cursor").css("transition", "transform .5s");
      });

      $(".whatWeDo__navigation .swiper-button-next").mouseenter(function (e) {
        $(".slider-cursor span").html("Next");
        $(".slider-cursor").css("opacity", "1");
        // console.log("sadasdasdsad");
      });

      $(".whatWeDo__navigation .swiper-button-prev").mouseenter(function (e) {
        $(".slider-cursor span").html("Prev");
        $(".slider-cursor").css("opacity", "1");
      });

      $(".whatWeDo__descripSlider .descripWrapper").mouseenter(function (e) {
        $(".slider-cursor").css("transform", "scale(0) translate(-50%, -50%)");
        $(".slider-cursor").css("transition", "transform .5s");
      });

      $(".whatWeDo__descripSlider .descripWrapper").mouseleave(function (e) {
        $(".slider-cursor").css("transform", "scale(1) translate(-50%, -50%)");
        $(".slider-cursor").css("transition", "transform .5s");
      });
    }
  }
}

//
if (document.querySelector(".process__thumb--slider")) {
  var processthumb = new Swiper(".process__thumb--slider", {
    speed: 1500,
    autoplay: false,
    breakpoints: {
      0: {
        slidesPerView: 2,
        slidesPerColumn: 2,
        spaceBetween: 0,

        grid: {
          rows: 2,
        },
      },
      1199: {
        watchSlidesProgress: true,
        slideToClickedSlide: true,
        slidesPerView: 4,
        loop: false,
        spaceBetween: 15,
        direction: "vertical",
        grid: {
          rows: 1,
        },
      },
    },
  });

  var processSlider = new Swiper(".process__slider", {
    speed: 1500,
    slidesPerView: 1,
    loop: false,
    allowTouchMove: false,
    autoplay: false,
    grabCursor: false,
    spaceBetween: 15,
    thumbs: {
      swiper: processthumb,
    },
  });

  var processGallery = new Swiper(".process__gallery", {
    speed: 1500,
    slidesPerView: 1,
    loop: false,
    allowTouchMove: false,
    parallax: true,
    direction: "horizontal",
    autoplay: false,

    navigation: {
      nextEl: ".process__leftsec .swiper-button-next",
      prevEl: ".process__leftsec .swiper-button-prev",
    },
    on: {
      init: function () {
        // Parallex--Start
        let swiper3 = this;
        for (let i = 0; i < swiper3.slides.length; i++) {
          $(swiper3.slides[i])
            .find(".img-wrapper")
            .attr({
              "data-swiper-parallax": 0.85 * swiper3.width,
              "data-swiper-paralalx-opacity": 0.5,
            });
        }
        // Parallex--End
      },
      resize: function () {
        this.update();
      },
    },
  });

  processSlider.controller.control = processGallery;
  processGallery.controller.control = processSlider;
}

//
if (document.querySelector(".tour__thumb")) {
  var tourThumb = new Swiper(".tour__thumb", {
    slidesPerView: "3",

    loop: false,
    // freeMode: true,
    speed: 1500,
    watchSlidesProgress: true,
    spaceBetween: 10,
    allowTouchMove: true,
    // initialSlide: 1,
  });

  var tourGallery = new Swiper(".tour__gallery", {
    init: false,
    slidesPerView: 1,
    loop: false,
    speed: 1500,
    allowTouchMove: false,
    parallax: true,
    navigation: {
      nextEl: ".tour__thumbWrapper .swiper-button-next",
      prevEl: ".tour__thumbWrapper .swiper-button-prev",
    },
    thumbs: {
      swiper: tourThumb,
    },
    on: {
      init: function () {
        // let swiper4 = this;
        // const currentSlide1 = swiper4.slides[0];
        // let dsrc1 = $(currentSlide1).find(".iframeData").attr("iframe-src");
        // let iFrame1 =
        //   '<iframe src="' +
        //   dsrc1 +
        //   '" style="width: 100%;height: 100%;"></iframe>';
        // setTimeout(function () {
        //   $(currentSlide1).find(".iframeData").append(iFrame1);
        // }, 500);
      },

      resize: function () {
        this.update();
      },
      // slideChange: function () {

      // },
    },
  });
  tourGallery.on("slideChange", function () {
    // const index_currentSlide = tourGallery.realIndex;
    tourGallery.slides.find("iframe").remove();
    // const currentSlide = tourGallery.slides[index_currentSlide];
    // let dsrc = $(currentSlide).find(".iframeData").attr("iframe-src");
    // let iFrame =
    //   '<iframe  src="' +
    //   dsrc +
    //   '" style="width: 100%; height: 100%;"></iframe>';
    // setTimeout(function () {
    //   $(currentSlide).find(".iframeData").append(iFrame);
    // }, 500);
    tourThumb.slideTo(tourGallery.realIndex + 1);
  });

  let anim2 = [...document.querySelectorAll(".virtual-tour")];
  let options = {
    rootMargin: "0px",
    threshold: 0.2,
  };

  let animate2 = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        tourGallery.init();
      }
    });
  };

  let observer = new IntersectionObserver(animate2, options);
  anim2.forEach((item, idx) => {
    observer.observe(item);
  });

  $(".vr-play-btn").click(function () {
    let dsrc = $(this).closest(".iframeData").attr("iframe-src");
    let iFrame =
      '<iframe  src="' +
      dsrc +
      '" style="width: 100%; height: 100%;"></iframe>';
    $(this).closest(".iframeData").append(iFrame);
  });
}

//
if (document.querySelector(".contactForm__slider")) {
  var contactForm__slider = new Swiper(".contactForm__slider", {
    slidesPerView: 1,
    spaceBetween: 15,
    loop: false,
    speed: 800,
    allowTouchMove: false,
    autoHeight: true,
    // effect: "fade",
    // fadeEffect: {
    //   crossFade: true,
    // },
    navigation: {
      nextEl: ".prev-next-wrapper .next-button",
      prevEl: ".prev-next-wrapper .previous-button",
    },
  });
  contactForm__slider.on("slideChange", function () {
    if (
      $(".prev-next-wrapper .next-button").hasClass("swiper-button-disabled")
    ) {
      $(".contactForm .submit-wrapper").fadeIn(250);
    } else {
      $(".contactForm .submit-wrapper").fadeOut(250);
    }
  });

  window.addEventListener("load", function () {
    // store tabs variable
    var myTabs = document.querySelectorAll(".contact__tabs .tab-item");
    function myTabClicks(tabClickEvent) {
      for (var i = 0; i < myTabs.length; i++) {
        myTabs[i].classList.remove("active");
      }
      var clickedTab = tabClickEvent.currentTarget;
      clickedTab.classList.add("active");
      tabClickEvent.preventDefault();
      var myContentPanes = document.querySelectorAll(".content-item");
      for (i = 0; i < myContentPanes.length; i++) {
        myContentPanes[i].classList.remove("active");
      }
      var anchorReference = tabClickEvent.target;
      var activePaneId = anchorReference.getAttribute("href");
      var activePane = document.querySelector(activePaneId);
      activePane.classList.add("active");
    }
    for (i = 0; i < myTabs.length; i++) {
      myTabs[i].addEventListener("click", myTabClicks);
    }
  });
}

//
if (document.querySelector(".social__slider")) {
  var socialSlider = new Swiper(".social__slider", {
    freeMode: true,
    longSwipes: true,
    slidesPerView: "auto",
    loop: false,
    speed: 12000,
    allowTouchMove: true,
    spaceBetween: 15,
    draggable: true,
    watchSlidesProgress: true,
    autoplay: {
      enable: true,
      delay: 1,
      disableOnInteraction: false,
    },
    scrollbar: {
      el: ".swiper-scrollbar",
      draggable: true,
      grabCursor: true,
    },
    pagination: {
      el: ".social .swiper-pagination",
      clickable: true,
    },
  });
  socialSlider.on("slideChange", function () {
    //social Slider Pagination
    $(".social-slider-wrapper .swiper-pagination-bullet").css({
      height: "22px",
    });
    $(
      ".social-slider-wrapper .swiper-pagination-bullet.swiper-pagination-bullet-active"
    )
      .prev()
      .css({ height: "33px" });
    $(
      ".social-slider-wrapper .swiper-pagination-bullet.swiper-pagination-bullet-active"
    )
      .next()
      .css({ height: "33px" });
    $(
      ".social-slider-wrapper .swiper-pagination-bullet.swiper-pagination-bullet-active"
    ).css({ height: "45px" });
  });
}

//
if (document.querySelector(".worksec")) {
  if (sw > 576) {
    // Work Section Animation
    var lFollowX = 0,
      lFollowY = 0,
      x = 0,
      y = 0,
      friction = 1 / 30;

    function moveBackground() {
      x += (lFollowX - x) * friction;
      y += (lFollowY - y) * friction;

      translate = "translate(" + x + "px , " + y + "px)";

      $(".worksec__cardsSlider").css({
        "-webit-transform": translate,
        "-moz-transform": translate,
        transform: translate,
      });

      window.requestAnimationFrame(moveBackground);
    }

    $(window).on("mousemove click", function (e) {
      var lMouseX = Math.max(
        -100,
        Math.min(100, $(window).width() / 2 - e.clientX)
      );
      var lMouseY = Math.max(
        -100,
        Math.min(100, $(window).height() / 2 - e.clientY)
      );
      lFollowX = (20 * lMouseX) / 100; // 100 : 12 = lMouxeX : lFollow
      lFollowY = (10 * lMouseY) / 100;
    });

    moveBackground();

    $(".worksec__card .card-wrapper").each(function () {
      $(this)
        .on("mouseover", function () {
          $(".worksec__cardsWrapper").addClass("hover");
          $(".worksec__card").removeClass("active");
          $(this).parent().addClass("active");
        })
        .on("mouseleave", function () {
          $(".worksec__cardsWrapper").removeClass("hover");
          $(this).parent().removeClass("active");
        });
    });
  }
}

//
if (document.querySelector(".videoBanner")) {
  $(document).ready(function () {
    setTimeout(function () {
      window._wq = window._wq || [];
      _wq.push({
        id: "jw96mvz5b5",
        onReady: function (video) {
          console.log("I got a handle to the video!", video);
        },
      });
      $(".vedio-wrapper").append(
        '<script src="//fast.wistia.com/assets/external/E-v1.js" async></script><div class="wistia_embed wistia_async_jw96mvz5b5"></div>'
      );
    }, 3500);

    $(".toggle").click(function () {
      $(".videoDescrip .head")
        .text(
          $(".videoDescrip .head").text() == "Watch the Film"
            ? "Pause the Film"
            : "Watch the Film"
        )
        .fadeIn("slow");

      if ($(this).hasClass("active")) {
        _wq.push({
          id: "jw96mvz5b5",
          onReady: function (video) {
            video.pause();
          },
        });
        $(this).removeClass("active");
        $(".top-btn").fadeIn(1000);
        $(".play").show();
        $(".pause").hide();
      } else {
        $(this).addClass("active");
        _wq.push({
          id: "jw96mvz5b5",
          onReady: function (video) {
            video.play();
          },
        });
        $(".top-btn").fadeOut(1000);
        $(".pause").show();
        $(".play").hide();
      }
    });
  });
}

var btn = $("#back-to-top");

$(window).scroll(function () {
  if ($(window).scrollTop() >= 300) {
    btn.addClass("show");
  } else {
    btn.removeClass("show");
  }
});

btn.on("click", function (e) {
  e.preventDefault();
  $("html, body").animate({ scrollTop: 0 }, "400");
});

// $(window)
//   .scroll(function () {
//     var windscroll = $(window).scrollTop();
//     if (windscroll >= 100) {
//       $(".homeParallax__slide").each(function (i) {
//         if ($(this).position().top <= windscroll - 0) {
//           $(".menu__menuitem a.active").removeClass("active");
//           $(".menu__menuitem a").eq(i).addClass("active");
//         }
//       });
//     } else {
//       $(".menu__menuitem ").removeClass("active");
//     }
//   })
//   .scroll();

var aboutcontent = new Swiper(".about-content", {
  speed: 1500,
  spaceBetween: 30,
  navigation: {
    nextEl: ".about-content .swiper-button-next",
    prevEl: ".about-content .swiper-button-prev",
  },
});
$(document).on("click", 'a[href^="#"]', function (e) {
  // target element id
  if ($(this).hasClass("tab-thumb")) {
  } else {
    var id = $(this).attr("href");

    // target element
    var $id = $(id);
    if ($id.length === 0) {
      return;
    }

    // prevent standard hash navigation (avoid blinking in IE)
    e.preventDefault();

    // top position relative to the document
    var pos = $id.offset().top;
    console.log("position", pos);
    // animated top scrolling

    if (sw < 576) {
      $("body, html").animate({ scrollTop: pos - 50 }, 100);
    } else {
      $("body, html").animate({ scrollTop: pos }, 100);
    }
  }
});

var ceoImgSlider = new Swiper(".ceo-img-slider", {
  spaceBetween: 20,
  speed: 1500,
  clickable: false,
  allowTouchMove: false,
  effect: "fade",
});

var CeoContentSlider = new Swiper(".ceo-content-slider", {
  speed: 1500,
  spaceBetween: 20,
  clickable: false,
  allowTouchMove: false,

  navigation: {
    nextEl: ".ceo-content-slider .swiper-button-next",
    prevEl: ".ceo-content-slider .swiper-button-prev",
  },
});
ceoImgSlider.controller.control = CeoContentSlider;
CeoContentSlider.controller.control = ceoImgSlider;

var CeoContentSlider = new Swiper(".grid-teamSlider", {
  speed: 1500,
  loop: false,
  slidesPerView: 3,
  spaceBetween: 10,
  breakpoints: {
    766: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
    992: {
      slidesPerView: 2,
      slidesPerColumn: 2,
      spaceBetween: 10,
      grid: {
        rows: 2,
      },
    },
    1200: {
      slidesPerView: 2,
      slidesPerColumn: 2,
      spaceBetween: 40,

      grid: {
        rows: 2,
      },
    },
  },
  navigation: {
    nextEl: ".grid-teamSlider .swiper-button-next",
    prevEl: ".grid-teamSlider .swiper-button-prev",
  },
});
$(".showBtn").click(function () {
  $(this).siblings(".description").toggleClass("showText");
  $(this).toggleClass("active");
});

if (sw < 576 && document.querySelector(".home")) {
  $(".estimate").click(function () {
    homeParallax.slideTo(6);
  });

  $(".one").click(function () {
    homeParallax.slideTo(0);
  });
  $(".two").click(function () {
    homeParallax.slideTo(1);
  });
  $(".three").click(function () {
    homeParallax.slideTo(2);
  });
  $(".four").click(function () {
    homeParallax.slideTo(3);
  });
  $(".five").click(function () {
    homeParallax.slideTo(4);
  });
  $(".six").click(function () {
    homeParallax.slideTo(5);
  });
  $(".seven").click(function () {
    homeParallax.slideTo(6);
  });
  $(".eight").click(function () {
    homeParallax.slideTo(7);
  });
}
let siteplantotal = document.querySelectorAll(
  ".site-plan-slider .swiper-slide"
).length;

var sitePlan = new Swiper(".site-plan-slider", {
  speed: 1000,
  effect: "fade",
  allowTouchMove: false,
  navigation: {
    nextEl: ".site-plan-wrapper .swiper-button-next",
    prevEl: ".site-plan-wrapper .swiper-button-prev",
  },
  on: {
    init: function () {
      // pagination--Start
      var currentSlide = document.querySelector(
        ".site-plan-wrapper .current-slide"
      );
      var allSlides = document.querySelector(
        ".site-plan-wrapper .total-slides"
      );

      var current = this.realIndex + 1;

      if (current > siteplantotal) current = 1;
      var idx = current < 10 ? "0" + current + "" : current + "";

      var tdx =
        siteplantotal < 10 ? "0" + siteplantotal + "" : siteplantotal + "";

      currentSlide.innerHTML = idx;
      allSlides.innerHTML = tdx;
      // pagination--End
    },
  },
});
sitePlan.on("slideChange", function () {
  var currentSlide = document.querySelector(
    ".site-plan-wrapper .current-slide"
  );
  var allSlides = document.querySelector(".site-plan-wrapper .total-slides");
  var current = this.realIndex + 1;
  if (current > siteplantotal) current = 1;
  var idx = current < 10 ? "0" + current + "" : current + "";
  var tdx = siteplantotal < 10 ? "0" + siteplantotal + "" : siteplantotal + "";
  currentSlide.innerHTML = idx;
  allSlides.innerHTML = tdx;
});

var workdetailThumbSlider = new Swiper(".workdetail-thumb-slider", {
  loop: false,
  spaceBetween: 23,
  slidesPerView: 3,
  freeMode: false,
  watchSlidesProgress: true,

  breakpoints: {
    767: {
      slidesPerView: 4,
    },
    990: {
      slidesPerView: 5,
    },
  },
});
var workdetailGallerySlider = new Swiper(".workdetail-gallery-slider", {
  spaceBetween: 10,
  speed: 1500,
  effect: "fade",
  allowTouchMove: true,
  // grabCursor: true,
  // parallax: true,
  navigation: {
    nextEl: ".work-detail-slider-wrapper .swiper-button-next",
    prevEl: ".work-detail-slider-wrapper .swiper-button-prev",
  },
  thumbs: {
    swiper: workdetailThumbSlider,
  },
  // on: {
  //   init: function () {
  //     // Parallex--Start
  //     let swiper4 = this;
  //     for (let i = 0; i < swiper4.slides.length; i++) {
  //       $(swiper4.slides[i])
  //         .find(".img-wrapper")
  //         .attr({
  //           "data-swiper-parallax": 0.85 * swiper4.width,
  //           "data-swiper-paralalx-opacity": 0.5,
  //         });
  //     }
  //     // Parallex--End
  //   },
  //   resize: function () {
  //     this.update();
  //   },
  // },
});

// if ("ontouchstart" in document.documentElement) {
//   $(".drop-down").mouseenter(function () {
//     $(this).siblings(".sub-menu-wrapper").slideDown();
//     $(this).addClass("active");
//   });
//   $(".drop-down").mouseleave(function () {
//     $(this).siblings(".sub-menu-wrapper").slideUp();
//     $(this).removeClass("active");
//   });
// } else {
//   $(".menu__menuitem").mouseenter(function () {
//     $(this).find(".sub-menu-wrapper").slideDown();
//     $(".drop-down").addClass("active");
//   });
//   $(".menu__menuitem").mouseleave(function () {
//     $(".menu__menuitem").find(".sub-menu-wrapper").slideUp();
//     $(".drop-down").removeClass("active");
//     setTimeout(function () {}, 10);
//   });
// }

var swiper = new Swiper(".brands", {
  slidesPerView: 2,
  spaceBetween: 3,

  breakpoints: {
    640: {
      slidesPerView: 3,
    },
    768: {
      slidesPerView: 4,
    },
    1024: {
      slidesPerView: 6,
    },
  },
});

var swiper = new Swiper(".solution_slide", {
  slidesPerView: 1,
  spaceBetween: 30,
  centeredSlides: false,
  slidesPerGroupSkip: -1,
  breakpoints: {
    768: {
      slidesPerView: 2.5,
    },
    991: {
      slidesPerView: 2.8,
    },
    1025: {
      slidesPerView: 3.3,
    },
  },
  pagination: {
    el: ".swiper-pagination",
    type: "progressbar",
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

// faq
$(document).ready(function () {
  $(".faq_tabs:nth-child(1)").find(".ans").slideDown();
});

$(".faq_tabs .title").click(function () {
  $(this).parents(".faq_tabs").toggleClass("active");
  $(this).parents(".faq_tabs").siblings(".faq_tabs").removeClass("active");

  if ($(this).parents(".faq_tabs").hasClass("active")) {
    $(this).siblings(".ans").slideDown();
    $(this).parents(".faq_tabs").siblings(".faq_tabs").find(".ans").slideUp();
  } else {
    $(this).siblings(".ans").slideUp();
  }
});

$(".close").click(function () {
  $(".fullpage_popup").slideUp();
});

$(".free-consulting").click(function () {
  $(".fullpage_popup").slideDown();
});

// circle-slider

var circle_content = new Swiper(".circle_content_slider", {
  slidesPerView: 1,
  spaceBetween: 0,
  speed: 1500,
});
var circle_thumb = new Swiper(".circle_thumb", {
  loop: true,
  spaceBetween: 10,
  slidesPerView: 4,
  freeMode: true,
  watchSlidesProgress: true,
});
var circle_img = new Swiper(".circle_img_slider", {
  loop: true,
  spaceBetween: 10,

  thumbs: {
    swiper: circle_thumb,
  },
});

// ======== work portfolio ======

$(".workPortfolio__tabs").on("click", ".tab-item", function (e) {
  let category = $(this).attr("data-menu");
  filterjson(category);
  e.stopPropagation();
  e.preventDefault();
});

//  our renovation

$(".professional-sec__col").mouseenter(function () {
  $(this).addClass("active").siblings().addClass("no-active");
});

$(".professional-sec__col").mouseleave(function () {
  $(this).removeClass("active").siblings().removeClass("no-active");
});

// custom select

(function () {
  var x, i, j, l, ll, selElmnt, a, b, c;
  /*look for any elements with the class "custom-select":*/
  x = document.getElementsByClassName("custom-select");
  l = x.length;
  for (i = 0; i < l; i++) {
    selElmnt = x[i].getElementsByTagName("select")[0];
    ll = selElmnt.length;
    /*for each element, create a new DIV that will act as the selected item:*/
    a = document.createElement("DIV");
    a.setAttribute("class", "select-selected");
    a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
    x[i].appendChild(a);
    /*for each element, create a new DIV that will contain the option list:*/
    b = document.createElement("DIV");
    b.setAttribute("class", "select-items select-hide");
    for (j = 1; j < ll; j++) {
      /*for each option in the original select element,
      create a new DIV that will act as an option item:*/
      c = document.createElement("DIV");
      c.innerHTML = selElmnt.options[j].innerHTML;
      c.addEventListener("click", function (e) {
        /*when an item is clicked, update the original select box,
          and the selected item:*/
        var y, i, k, s, h, sl, yl;
        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
        sl = s.length;
        h = this.parentNode.previousSibling;
        for (i = 0; i < sl; i++) {
          if (s.options[i].innerHTML == this.innerHTML) {
            s.selectedIndex = i;
            h.innerHTML = this.innerHTML;
            y = this.parentNode.getElementsByClassName("same-as-selected");
            yl = y.length;
            for (k = 0; k < yl; k++) {
              y[k].removeAttribute("class");
            }
            this.setAttribute("class", "same-as-selected");
            break;
          }
        }
        h.click();
      });
      b.appendChild(c);
    }
    x[i].appendChild(b);
    a.addEventListener("click", function (e) {
      /*when the select box is clicked, close any other select boxes,
        and open/close the current select box:*/
      let heroformDropdown = document.querySelectorAll(
        ".hero-form__col:not(.hero-form__col--btnn) .hero-form__dropdown"
      );
      heroformDropdown.forEach(function (elmt) {
        elmt.classList.remove("active");
      });

      e.stopPropagation();
      closeAllSelect(this);
      this.nextSibling.classList.toggle("select-hide");
      this.classList.toggle("select-arrow-active");
    });
  }
  function closeAllSelect(elmnt) {
    /*a function that will close all select boxes in the document,
    except the current select box:*/
    var x,
      y,
      i,
      xl,
      yl,
      arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    xl = x.length;
    yl = y.length;
    for (i = 0; i < yl; i++) {
      if (elmnt == y[i]) {
        arrNo.push(i);
      } else {
        y[i].classList.remove("select-arrow-active");
      }
    }
    for (i = 0; i < xl; i++) {
      if (arrNo.indexOf(i)) {
        x[i].classList.add("select-hide");
      }
    }
  }

  document.addEventListener("click", closeAllSelect);
})();

$(".vr .blog_card").on("click", function () {
  var $vr = $(this).attr("data-vr");

  if ($vr == "all") {
    $(".vr__wrapper .vr__galleryWrapper").removeClass("d-none");
  } else {
    $(".vr__wrapper .vr__galleryWrapper").addClass("d-none");
    $(".vr__wrapper .vr__galleryWrapper[data-vr=" + $vr + "]").removeClass(
      "d-none"
    );
  }
});

$(".vr-play-btn").click(function () {
  let dsrc = $(this).closest(".iframeData").attr("iframe-src");
  let iFrame =
    '<iframe  src="' + dsrc + '" style="width: 100%; height: 100%;"></iframe>';
  $(this).closest(".iframeData").append(iFrame);
});

$(".fit-out_slide").click(function () {
  $(".fit_out-slide").addClass("active");
});
$(".fit_out-slide .cross").click(function () {
  $(".fit_out-slide").removeClass("active");
});
if (document.querySelector(".workPortfolio__slider")) {
  var workPortfolio__slider = new Swiper(".workPortfolio__slider", {
    slidesPerView: 1,
    loop: false,
    allowTouchMove: false,
    observer: true,
    observeParents: true,
    spaceBetween: 10,
    scrollbar: {
      el: ".workPortfolio__sliderWrapper .swiper-scrollbar",
      draggable: true,
    },
    breakpoints: {
      640: {
        spaceBetween: 0,

        slidesPerView: "auto",

        grid: {
          rows: 2,
        },
      },
    },
  });

  $(".workPortfolio__tabs li").on("click", function () {
    $(this).addClass("active").siblings().removeClass("active");
    var $data = $(this).attr("data-menu");
    console.log($data);

    if ($data == "allCards") {
      $(".workPortfolio__slide").removeClass("d-none");
    } else {
      $(".workPortfolio__slide").addClass("d-none");

      $(".workPortfolio__slide[data-menu=" + $data + "]").removeClass("d-none");
    }
  });
}

// process ciecle slider
var process = new Swiper(".circle-process_slider", {
  // cssMode: true,
  loop: true,
  effect: "fade",
  fadeEffect: {
    crossFade: true,
  },
  autoplay: {
    enabled: true,
    delay: 3000,
    disableOnInteraction: false,
  },
  speed: 1000,
  navigation: {
    nextEl: ".circle-process-wrapper .swiper-button-next",
    prevEl: ".circle-process-wrapper .swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});
var herogallery = new Swiper(".herogallery", {
  spaceBetween: 0,
  loop: true,
  speed: 1000,

  effect: "fade",
});
process.controller.control = herogallery;
herogallery.controller.control = process;
var p = 1;
process.on("slideChange", function (e) {
  console.log(this.realIndex);
  if (this.realIndex == 0) {
    $(".circle_svg svg").css({
      transform: `rotate(0deg)`,
    });
  } else if (this.realIndex == 1) {
    $(".circle_svg svg").css({
      transform: `rotate(90deg)`,
    });
  } else if (this.realIndex == 2) {
    $(".circle_svg svg").css({
      transform: `rotate(180deg)`,
    });
  } else if (this.realIndex == 3) {
    $(".circle_svg svg").css({
      transform: `rotate(270deg)`,
    });
  }
});

$(".commercial-btn").click(function () {
  $(".commercial-btn").addClass("active");
  $(".residential-btn").removeClass("active");
  $(".commercial").addClass("show");
  $(".residentials").removeClass("show");
});

$(".residential-btn").click(function () {
  $(".residential-btn").addClass("active");
  $(".commercial-btn").removeClass("active");
  $(".residentials").addClass("show");
  $(".commercial").removeClass("show");
});

// portfoio

if (document.querySelectorAll(".portfolio_slider")) {
  var swiper = new Swiper(".portfolio_slider", {
    spaceBetween: 30,
    effect: "fade",
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });
}
// portfoio-detail
if (document.querySelector(".portfolio_slider-section__slider")) {
  var socialSlider = new Swiper(".portfolio_slider-section__slider", {
    freeMode: true,
    longSwipes: true,
    slidesPerView: "auto",
    loop: false,
    speed: 12000,
    allowTouchMove: true,
    spaceBetween: 40,
    draggable: true,
    watchSlidesProgress: true,
    autoplay: {
      enable: true,
      delay: 1,
      disableOnInteraction: false,
    },
    scrollbar: {
      el: ".swiper-scrollbar",
      draggable: true,
      grabCursor: true,
    },
    pagination: {
      el: ".social .swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      360: {
        slidesPerView: 1,
        autoplay: false,
        freeMode: false,
        longSwipes: false,
      },
      480: {
        slidesPerView: 2,
        autoplay: false,
      },
      768: {
        slidesPerView: 3,
        autoplay: false,
      },
      1200: {
        slidesPerView: "auto",
        autoplay: {
          enable: true,
          delay: 1,
          disableOnInteraction: false,
        },
      },
    },
  });
}

// Master Slider
if (document.querySelector(".master-slider")) {
  var masterSlider = new Swiper(".master-slider", {
    slidesPerView: 1,
    loop: false,
    speed: 1500,
    effect: "fade",
    allowTouchMove: true,
    spaceBetween: 20,
    parallax: true,
    navigation: {
      nextEl: ".master-nav-arrows .swiper-button-next",
      prevEl: ".master-nav-arrows .swiper-button-prev",
    },
    // on: {
    //   init: function () {
    //     // Parallex--Start
    //     let reqSliderParallax = this;
    //     for (let i = 0; i < reqSliderParallax.slides.length; i++) {
    //       $(reqSliderParallax.slides[i])
    //         .find(".img-wrapper")
    //         .attr({
    //           "data-swiper-parallax": 0 * reqSliderParallax.width,
    //           "data-swiper-paralalx-opacity": 0.5,
    //         });
    //     }
    //     // Parallex--End
    //   },
    //   resize: function () {
    //     this.update();
    //   },
    // },
  });
}

//client Requirment Slider
if (document.querySelector(".req-slider1")) {
  var reqSlider = new Swiper(".req-slider1", {
    slidesPerView: 1,
    loop: false,
    speed: 1500,
    // allowTouchMove: true,
    parallax: true,
    pagination: {
      el: ".req-slider1 .swiper-pagination",
      clickable: true,
    },
    on: {
      init: function () {
        // Parallex--Start
        let reqSliderParallax = this;
        for (let i = 0; i < reqSliderParallax.slides.length; i++) {
          $(reqSliderParallax.slides[i])
            .find(".img-wrapper")
            .attr({
              "data-swiper-parallax": 1 * reqSliderParallax.width,
              "data-swiper-paralalx-opacity": 0.5,
            });
        }
        // Parallex--End
      },
      resize: function () {
        this.update();
      },
    },
  });
}

// Challenge Slider Initialization
if (document.querySelector(".challenge-slider")) {
  var challengeSwiper = new Swiper(".challenge-slider", {
    slidesPerView: 1,
    loop: false,
    effect: "slide",
    speed: 1500,
    parallax: true,
    on: {
      init: function () {
        // Parallex--Start
        let reqSliderParallax = this;
        for (let i = 0; i < reqSliderParallax.slides.length; i++) {
          $(reqSliderParallax.slides[i])
            .find(".img-wrapper")
            .attr({
              "data-swiper-parallax": 0 * reqSliderParallax.width,
              "data-swiper-paralalx-opacity": 0.5,
            });
        }
        // Parallex--End
      },
      resize: function () {
        this.update();
      },
    },
  });

  var challengeTextSwiper = new Swiper(".challenge-slider-text", {
    spaceBetween: 30,
    slidesPerView: 1,
    speed: 1500,
    navigation: {
      nextEl: ".text-slider-col .swiper-button-next-challenge",
      prevEl: ".text-slider-col .swiper-button-prev-challenge",
    },
  });

  challengeSwiper.controller.control = challengeTextSwiper;
  challengeTextSwiper.controller.control = challengeSwiper;
}

// start

if (document.querySelector(".design-render")) {
  var activePos = $(".process__tabs .active").position();

  // Change Position
  function changePos() {
    // Update Position
    activePos = $(".process__tabs .active").position();

    // Change Position & Width
    $(".border")
      .stop()
      .css({
        left: activePos.left,
        width: $(".process__tabs .active").outerWidth(),
      });
  }
  setTimeout(() => {
    changePos();
  }, 2000);

  let design__render__tab = document.querySelectorAll(".process__tabs");
  design__render__tab.forEach((designTabs) =>
    designTabs.addEventListener("click", (e) => tabFunc(e, designTabs))
  );

  function tabFunc(evt, item) {
    if (evt.target.matches("li")) {
      item.querySelectorAll("li").forEach((el) => {
        el.classList.remove("active");

        let design_render__bg_sliders = item.parentNode.querySelectorAll(
          ".js-sliders .js-swiper"
        );

        design_render__bg_sliders.forEach((el) => {
          el.style.display = "none";
        });

        let design_render__number_slider = item.parentNode.querySelectorAll(
          ".js-slidernumber .js-swiper"
        );
        if (design_render__number_slider) {
          design_render__number_slider.forEach((el) => {
            el.style.display = "none";
          });
        }
      });
      let bg_Slider = item.parentNode.querySelector(
        `.bg-slider-${evt.target.dataset.designRender}`
      );
      let number_Slider = item.parentNode.querySelector(
        `.number-slider-${evt.target.dataset.designRender}`
      );
      bg_Slider.style.display = "block";
      if (number_Slider) {
        number_Slider.style.display = "block";
      }
      evt.target.classList.add("active");
      changePos();
    }
  }
  var activePos2 = $(".tabs___render .active").position();
  if (window.matchMedia("(min-width: 991px)").matches) {
    // Change Position
    function changePos2() {
      // Update Position
      activePos2 = $(".tabs___render .active").position();

      // Change Position & Width
      $(".border2")
        .stop()
        .css({
          left: activePos2.left,
          width: $(".tabs___render .active").outerWidth(),
        });
    }
    setTimeout(()=>{
        changePos2();
    }, 3000)
  }
  let design__render__tab2 = document.querySelectorAll(".tabs___render");
  design__render__tab2.forEach((designTabs) =>
    designTabs.addEventListener("click", (e) => tabFunc2(e, designTabs))
  );

  function tabFunc2(evt, item) {
    if (evt.target.matches("li")) {
      item.querySelectorAll("li").forEach((el) => {
        el.classList.remove("active");

        let design_render__bg_sliders = item.parentNode.querySelectorAll(
          ".js-sliders .js-swiper"
        );

        design_render__bg_sliders.forEach((el) => {
          el.style.display = "none";
        });

        let design_render__number_slider = item.parentNode.querySelectorAll(
          ".js-slidernumber .js-swiper"
        );
        if (design_render__number_slider) {
          design_render__number_slider.forEach((el) => {
            el.style.display = "none";
          });
        }
      });
      let bg_Slider = item.parentNode.querySelector(
        `.bg-slider-${evt.target.dataset.designRender}`
      );
      let number_Slider = item.parentNode.querySelector(
        `.number-slider-${evt.target.dataset.designRender}`
      );
      bg_Slider.style.display = "block";
      if (number_Slider) {
        number_Slider.style.display = "block";
      }
      evt.target.classList.add("active");
      changePos2();
    }
  }
}
// Intial Border Position

// end

// Process-tab Slider
if (document.querySelector(".process-tab-slider")) {
  let slidersTabsPane = document.querySelectorAll(".process-tab-slider");

  let sliders = [];

  slidersTabsPane.forEach((item, i) => {
    sliders[i] = new Swiper(item, {
      slidesPerView: 1,
      loop: false,
      speed: 1500,
      allowTouchMove: true,
      parallax: true,
      navigation: {
        nextEl: ".process-tab-nav-arrows .swiper-button-next",
        prevEl: ".process-tab-nav-arrows .swiper-button-prev",
      },
      on: {
        init: function () {
          // Parallex--Start
          let reqSliderParallax = this;
          for (let i = 0; i < reqSliderParallax.slides.length; i++) {
            $(reqSliderParallax.slides[i])
              .find(".img-wrapper")
              .attr({
                "data-swiper-parallax": 0 * reqSliderParallax.width,
                "data-swiper-paralalx-opacity": 0.5,
              });
          }
          // Parallex--End
        },
        resize: function () {
          this.update();
        },
      },
    });
  });
}

if (document.querySelector(".design-render__number-slider")) {
  let swiper_number_bedroom = new Swiper(
    ".design-render__number-slider .number-slider-bedroom",
    {
      spaceBetween: 30,
      slidesPerView: 2,
      freeMode: true,
      watchSlidesProgress: true,
      grabCursor: false,
      observeParents: true,
      observer: true,
      // allowTouchMove: false,
      breakpoints: {
        576: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 6,
        },
      },
    }
  );

  let swiper_bg = new Swiper(".design-render__bg-slider .bg-slider-bedroom", {
    spaceBetween: 0,
    slidesPerView: 1,
    observeParents: true,
    observer: true,
    speed: 1500,
    thumbs: {
      swiper: swiper_number_bedroom,
    },
    on: {
      slideChange: function () {
        this.slides.find("iframe").remove();
        //   this.slides.find(".vr-play-btn-2").classList.remove("hideBtn");
        console.log(this.slides, "abc");
        const vrPlayButton = document.querySelectorAll(".vr-play-btn-2");
        vrPlayButton.forEach(function (vrPlayButton) {
          if (vrPlayButton && vrPlayButton.classList.contains("hideBtn")) {
            vrPlayButton.classList.remove("hideBtn");
          }
        });
      },
    },
  });

  // for dining room
  let swiper_number_diningroom = new Swiper(
    ".design-render__number-slider .number-slider-diningroom",
    {
      spaceBetween: 30,
      slidesPerView: 2,
      freeMode: true,
      watchSlidesProgress: true,
      grabCursor: false,
      observeParents: true,
      observer: true,
      // allowTouchMove: false,
      breakpoints: {
        576: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 6,
        },
      },
    }
  );

  let swiper_bg_diningroom = new Swiper(
    ".design-render__bg-slider .bg-slider-diningroom",
    {
      spaceBetween: 0,
      slidesPerView: 1,
      observeParents: true,
      observer: true,
      speed: 1500,
      thumbs: {
        swiper: swiper_number_diningroom,
      },
    }
  );

  // for bathroom
  let swiper_number_bathroom = new Swiper(
    ".design-render__number-slider .number-slider-bathroom",
    {
      spaceBetween: 30,
      slidesPerView: 2,
      freeMode: true,
      watchSlidesProgress: true,
      grabCursor: false,
      observeParents: true,
      observer: true,
      // allowTouchMove: false,
      breakpoints: {
        576: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 6,
        },
      },
    }
  );

  let swiper_bg_bathroom = new Swiper(
    ".design-render__bg-slider .bg-slider-bathroom",
    {
      spaceBetween: 0,
      slidesPerView: 1,
      observeParents: true,
      observer: true,
      speed: 1500,
      thumbs: {
        swiper: swiper_number_bathroom,
      },
    }
  );

  // for launchroom
  let swiper_number_launchroom = new Swiper(
    ".design-render__number-slider .number-slider-launchroom",
    {
      spaceBetween: 30,
      slidesPerView: 2,
      freeMode: true,
      watchSlidesProgress: true,
      grabCursor: false,
      observeParents: true,
      observer: true,
      // allowTouchMove: false,
      breakpoints: {
        576: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 6,
        },
      },
    }
  );

  let swiper_bg_launchroom = new Swiper(
    ".design-render__bg-slider .bg-slider-launchroom",
    {
      spaceBetween: 0,
      slidesPerView: 1,
      observeParents: true,
      observer: true,
      speed: 1500,
      thumbs: {
        swiper: swiper_number_launchroom,
      },
    }
  );

  // for movieroom
  let swiper_number_movieroom = new Swiper(
    ".design-render__number-slider .number-slider-movieroom",
    {
      spaceBetween: 30,
      slidesPerView: 2,
      freeMode: true,
      watchSlidesProgress: true,
      grabCursor: false,
      observeParents: true,
      observer: true,
      // allowTouchMove: false,
      breakpoints: {
        576: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 6,
        },
      },
    }
  );

  let swiper_bg_movieroom = new Swiper(
    ".design-render__bg-slider .bg-slider-movieroom",
    {
      spaceBetween: 0,
      slidesPerView: 1,
      observeParents: true,
      observer: true,
      speed: 1500,
      thumbs: {
        swiper: swiper_number_movieroom,
      },
    }
  );

  // guestroom
  let swiper_number_guestroom = new Swiper(
    ".design-render__number-slider .number-slider-guestroom",
    {
      spaceBetween: 30,
      slidesPerView: 2,
      freeMode: true,
      watchSlidesProgress: true,
      grabCursor: false,
      observeParents: true,
      observer: true,
      // allowTouchMove: false,
      breakpoints: {
        576: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 6,
        },
      },
    }
  );
  let swiper_bg_guestroom = new Swiper(
    ".design-render__bg-slider .bg-slider-guestroom",
    {
      spaceBetween: 0,
      slidesPerView: 1,
      observeParents: true,
      observer: true,
      speed: 1500,
      thumbs: {
        swiper: swiper_number_guestroom,
      },
    }
  );
}

if (document.querySelector(".h-life-beyond__slider-wrapper")) {
  let swiper_life_beyondThumb = new Swiper(
    ".h-life-beyond__slider-tabs .swiper",
    {
      spaceBetween: 0,
      slidesPerView: "auto",
      freeMode: true,
      watchSlidesProgress: true,
      grabCursor: false,
      observeParents: true,
      observer: true,
      // allowTouchMove: false,
      direction: "vertical",
      // breakpoints: {
      //   576: {
      //     slidesPerView: 2,
      //   },
      //   768: {
      //     slidesPerView: 3,
      //   },
      //   1200: {
      //     slidesPerView: "auto",
      //   },
      // },
    }
  );

  let swiperLifeBeyond = new Swiper(".h-life-beyond__slider-content .swiper", {
    spaceBetween: 0,
    speed: 1500,
    observeParents: true,
    observer: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    // navigation: {
    //   nextEl: ".swiper-button-next",
    //   prevEl: ".swiper-button-prev",
    // },
    thumbs: {
      swiper: swiper_life_beyondThumb,
    },
  });
}

if (document.querySelector(".kitchen-slider")) {
  var kitchenSlider = new Swiper(".kitchen-slider", {
    slidesPerView: 1,
    loop: false,
    speed: 1500,
    parallax: true,
    pagination: {
      el: ".kitchen-slider .swiper-pagination",
      clickable: true,
    },
    on: {
      init: function () {
        console.log("Swiper initialized!");
        let reqSliderParallax = this;
        reqSliderParallax.slides.forEach((slide) => {
          const imgWrapper = slide.querySelector(".img-wrapper");
          if (imgWrapper) {
            imgWrapper.setAttribute(
              "data-swiper-parallax",
              0 * reqSliderParallax.width
            );
            imgWrapper.setAttribute("data-swiper-paralalx-opacity", 0.5);
          }
        });
      },
      resize: function () {
        this.update();
      },
      slideChange: function () {
        console.log("Slide change triggered");

        // Remove iframe elements
        this.slides.forEach((slide) => {
          const iframe = slide.querySelector("iframe");
          if (iframe) {
            iframe.remove();
          }
        });

        // Show play buttons
        const vrPlayButtons = document.querySelectorAll(".vr-play-btn-3");
        vrPlayButtons.forEach((button) => {
          if (button.classList.contains("hideBtn")) {
            button.classList.remove("hideBtn");
          }
        });
      },
    },
  });
}

$(".vr-play-btn-3").click(function () {
  let iframeData = $(this).parents(".btn__wrapper").siblings(".iframeData");
  let dsrc = iframeData.attr("iframe-src");
  let iFrame = `<iframe src="${dsrc}" style="width: 100%; height: 100%;"></iframe>`;
  iframeData.append(iFrame);
  $(this).addClass("hideBtn");
});

$(".responsive-video").on("loadeddata", function () {
  $(".video__loading__state").remove();
});

$(".video__run").click(function () {
  const video = $(this).siblings("video").get(0); // Get the sibling video element
  console.log(video, "video");

  if (video) {
    if (video.muted) {
      video.muted = false; // Unmute the video
      video
        .play()
        .then(() => {
          console.log("Video is now unmuted and playing.");
          $(this).text("Mute"); // Change button text to "Mute"
        })
        .catch((error) => {
          console.error("Error playing video:", error);
        });
    } else {
      video.muted = true; // Mute the video
      $(this).text("Unmute"); // Change button text to "Unmute"
      console.log("Video is now muted.");
    }
  } else {
    console.error("No video element found.");
  }
});

$(".vr-play-btn-2").click(function () {
  let dsrc = $(this).closest(".iframeData").attr("iframe-src");
  let iFrame =
    '<iframe  src="' + dsrc + '" style="width: 100%; height: 100%;"></iframe>';
  $(this).closest(".iframeData").append(iFrame);
  $(this).addClass("hideBtn");
  $(this).siblings(".close_vr").addClass("active");
});

$(".close_vr").click(function () {
  $(this).closest(".iframeData").find("iframe").remove();
  $(this).removeClass("active");
  $(this).siblings(".vr-play-btn-2").removeClass("hideBtn");
});

document.querySelectorAll(".circle").forEach((circle) => {
  circle.addEventListener("click", function () {
    const kitchenValue = this.getAttribute("data-kitchen");

    document.querySelectorAll(".img-box").forEach((imgBox) => {
      if (imgBox.getAttribute("data-kitchen") === kitchenValue) {
        imgBox.classList.add("active");
      } else {
        imgBox.classList.remove("active");
      }
    });
  });
});

// Landscape Slider Initialization
if (document.querySelector(".landscape-slider")) {
  var landscapeSwiper = new Swiper(".landscape-slider", {
    slidesPerView: 1,
    loop: false,
    effect: "slide",
    speed: 1500,
    parallax: true,
    pagination: {
      el: ".landscape-slider-text .swiper-pagination",
      type: "custom",
      renderCustom: function (swiper, current, total) {
        // Format current and total slide numbers
        var currentSlide = current < 10 ? "0" + current : current;
        var totalSlides = total < 10 ? "0" + total : total;
        return `<span class="current-slide">${currentSlide}</span>/<span class="total-slides">${totalSlides}</span>`;
      },
    },
    navigation: {
      nextEl: ".swiper-button-next-landscape",
      prevEl: ".swiper-button-prev-landscape",
    },
    on: {
      init: function () {
        // Parallex--Start
        let reqSliderParallax = this;
        for (let i = 0; i < reqSliderParallax.slides.length; i++) {
          $(reqSliderParallax.slides[i])
            .find(".img-wrapper")
            .attr({
              "data-swiper-parallax": 0.85 * reqSliderParallax.width,
              "data-swiper-paralalx-opacity": 0.5,
            });
        }

        var current = this.realIndex + 1;
        var total = this.slides.length;
        var currentFormatted = current < 10 ? "0" + current : current;
        var totalFormatted = total < 10 ? "0" + total : total;
        updatePaginationNumbers(currentFormatted, totalFormatted);
        // Parallex--End
      },
      resize: function () {
        this.update();
      },
      slideChange: function () {
        // Update pagination when the slide changes
        var current = this.realIndex + 1;
        var total = this.slides.length;
        var currentFormatted = current < 10 ? "0" + current : current;
        var totalFormatted = total < 10 ? "0" + total : total;
        updatePaginationNumbers(currentFormatted, totalFormatted);
      },
    },
  });

  // Function to update the pagination numbers
  function updatePaginationNumbers(currentSlide, totalSlides) {
    var currentSlideElem = document.querySelector(
      ".landscape-slider-text .current-slide"
    );
    var totalSlidesElem = document.querySelector(
      ".landscape-slider-text .total-slides"
    );

    // console.log("========= pagin", totalSlides);

    if (currentSlideElem && totalSlidesElem) {
      currentSlideElem.textContent = currentSlide;
      totalSlidesElem.textContent = totalSlides;
    }
  }
}
if (document.querySelector(".landscaping-sec")) {
  if (document.querySelector(".landscape-slider")) {
    var landscapeSwiper = new Swiper(".landscape-slider", {
      slidesPerView: 1,
      loop: false,
      effect: "slide",

      speed: 1500,
      parallax: true,
      pagination: {
        el: ".landscape-slider-text .swiper-pagination",
        type: "custom",
        renderCustom: function (swiper, current, total) {
          // Format current and total slide numbers
          var currentSlide = current < 10 ? "0" + current : current;
          var totalSlides = total < 10 ? "0" + total : total;
          return `<span class="current-slide">${currentSlide}</span>/<span class="total-slides">${totalSlides}</span>`;
        },
      },
      navigation: {
        nextEl: ".swiper-button-next-landscape",
        prevEl: ".swiper-button-prev-landscape",
      },

      allowTouchMove: false,
    });

    // Function to update the pagination numbers
    function updatePaginationNumbers(currentSlide, totalSlides) {
      var currentSlideElem = document.querySelector(
        ".landscape-slider-text .current-slide"
      );
      var totalSlidesElem = document.querySelector(
        ".landscape-slider-text .total-slides"
      );

      if (currentSlideElem && totalSlidesElem) {
        currentSlideElem.textContent = currentSlide;
        totalSlidesElem.textContent = totalSlides;
      }
    }
  }

  if (document.querySelector(".side__image___slider")) {
    var sideimageSwiper = new Swiper(".side__image___slider", {
      slidesPerView: 1,
      loop: false,
      effect: "slide",
      spaceBetween: 20,
      speed: 1500,
      allowTouchMove: false,
    });
  }

  sideimageSwiper.controller.control = landscapeSwiper;
  landscapeSwiper.controller.control = sideimageSwiper;
}

document.addEventListener("DOMContentLoaded", function () {
  const playIcons = document.querySelectorAll(".play-icon");

  playIcons.forEach((icon) => {
    icon.addEventListener("click", function () {
      const video = this.previousElementSibling; // Get the video element before the play icon
      video.play(); // Play the video
      this.style.display = "none"; // Hide the play icon once the video starts playing

      // Add click event to video to pause when clicked after playing
      video.addEventListener("click", function () {
        if (!video.paused) {
          video.pause(); // Pause the video if playing
          icon.style.display = "flex"; // Show the play icon again when video is paused
        }
      });
    });
  });
});

if (document.querySelector(".slider-construction")) {
  var contructionSlider = new Swiper(".slider-construction", {
    slidesPerView: 1, // Adjust to 'auto' to manage custom slide width
    spaceBetween: 10, // No space between slides
    centeredSlides: true, // Center the active slide
    loop: true, // Enable looping
    grabCursor: false, // Show grab cursor
    breakpoints: {
      767: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 100,
      },
    },
    on: {
      slideChange: function () {
        // Pause all videos when the slide changes
        const videos = document.querySelectorAll(".slider-construction .video");
        const playIcons = document.querySelectorAll(
          ".slider-construction .play-icon"
        );

        videos.forEach((video) => {
          video.pause();
          video.currentTime = 0; // Reset the video to the beginning
        });
        playIcons.forEach((icon) => {
          icon.style.display = "flex"; // Show play icon
        });
      },
    },
  });
}

if (document.querySelector(".exterior-slider")) {
  var exteriorSwiper = new Swiper(".exterior-slider", {
    slidesPerView: 1,
    loop: false,
    speed: 1500,
    allowTouchMove: true,
    parallax: true,
    pagination: {
      el: ".swiper-pagination", // Make sure you have this pagination class in the HTML
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next-exterior ", // Use unique class for next button
      prevEl: ".swiper-button-prev-exterior ", // Use unique class for prev button
    },
  });
}

// Exterior Slider Initialization
if (document.querySelector(".slider-interior-col-right")) {
  let galleryThumbs = new Swiper(".slider-interior-col-right .gallery-thumbs", {
    loop: false, // Enable loop mode
    spaceBetween: 20,
    slidesPerView: 3,
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,

    breakpoints: {
      767: {
        spaceBetween: 20,
        slidesPerView: 4,
      },
    },
  });

  let interiorSwiper = new Swiper(
    ".slider-interior-col-right .interior-slider",
    {
      slidesPerView: 1,
      loop: false,
      speed: 1500,
      allowTouchMove: true,
      parallax: true,
      navigation: {
        nextEl: ".slider-interior-col-right .swiper-button-next-interior ", // Use unique class for next button
        prevEl: ".slider-interior-col-right .swiper-button-prev-interior ", // Use unique class for prev button
      },
      thumbs: {
        swiper: galleryThumbs,
      },
    }
  );
}

if (document.querySelector(".slider-interior-col-left")) {
  let interiorSwiperr = new Swiper(
    ".slider-interior-col-left .interior-slider",
    {
      slidesPerView: 1,
      loop: false,
      speed: 1500,
      allowTouchMove: true,
      parallax: true,
      navigation: {
        nextEl: ".slider-interior-col-left .swiper-button-next-interior ",
        prevEl: ".slider-interior-col-left .swiper-button-prev-interior ",
      },
      pagination: {
        el: ".slider-interior-col-left .interior-slider .swiper-pagination",
      },
    }
  );
}
